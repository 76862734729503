import React from "react";
import Typography from "@mui/material/Typography";

function ResultsFooter() {
  return (
    <>
      <Typography paragraph={true}>
        We are pleased to provide the above estimates for Valuation Surveys with
        regard to a property located in your area for the estimated value of the
        property you told us.
      </Typography>
      <Typography paragraph={true}>
        Before appointing a supplier you should ensure that they have
        satisfactory, qualifications, accreditation, experience and insurance
        appropriate for the work.
      </Typography>
      <Typography paragraph={true}>
        An email with the above prices and contact details has been sent to you,
        so you can refer to it later at your convenience.
      </Typography>
    </>
  );
}

export default ResultsFooter;
